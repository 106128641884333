import { createRouter, createWebHistory } from '@ionic/vue-router';

import client from './authenticated/client';

const routes = [
  {
    path: '/',
    redirect: '/store-search',
  },
  // {
  //   path: '/home/:mid/:ut',
  //   name: 'home-merchant-membertype',
  //   component: () => import('../pages/home/Index.vue'),
  // },
  // {
  //   path: '/home/:mid',
  //   name: 'home-merchant',
  //   component: () => import('../pages/home/Index.vue'),
  // },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import('../pages/home/Index.vue'),
  // },
  ...client,
  {
    path: '/login/:mid',
    name: 'login-merchant',
    component: () => import('../pages/Login.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/Login.vue'),
  },
  {
    path: '/resetpassword/:url',
    name: 'resetpassword',
    component: () => import('../pages/ResetPassword.vue'),
  },
  {
    path: '/recovery-password',
    name: 'recovery-password',
    component: () => import('../pages/RecoverPassword.vue'),
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('../pages/Register.vue'),
  // },
  // {
  //   path: '/register/:mid',
  //   name: 'register merchant',
  //   component: () => import('../pages/Register.vue'),
  // },
  {
    path: '/phone/:mid',
    name: 'phone-merchant',
    component: () => import('../pages/Phone.vue'),
  },
  {
    path: '/phone',
    name: 'phone',
    component: () => import('../pages/Phone.vue'),
  },
  {
    path: '/setup-password',
    name: 'setup-password',
    component: () => import('../pages/SetupPassword.vue'),
  },
  {
    path: '/store-home/:mid/:login',
    name: 'store-home-mid-login',
    component: () => import('../pages/StoreHome.vue'),
  },
  {
    path: '/store-home/:mid',
    name: 'store-home-mid',
    component: () => import('../pages/StoreHome.vue'),
  },
  {
    path: '/store-home',
    name: 'store-home',
    component: () => import('../pages/StoreHome.vue'),
  },
  {
    path: '/store-search',
    name: 'store-search',
    component: () => import('../pages/StoreSearch.vue'),
  },
  {
    path: '/smsverify',
    name: 'sms-verify',
    component: () => import('../pages/SmsVerify.vue'),
  },
  {
    path: '/smsverify/resetpw',
    name: 'sms-verify-resetpw',
    component: () => import('../pages/SmsVerify_resetpw.vue'),
  },
  {
    path: '/store-info',
    name: 'store-info',
    component: () => import('../pages/StoreInfo.vue'),
  },
  {
    path: '/banner-preview/:id',
    name: 'banner-preview',
    component: () => import('../pages/BannerPreview.vue'),
  },
  {
    path: '/card-info/:data',
    name: 'card-info',
    component: () => import('../pages/CardInfo.vue'),
  },
  {
    path: '/points-history/:id',
    name: 'points-history',
    component: () => import('../pages/PointsHistory.vue'),
  },
  {
    path: '/balance-history/:id',
    name: 'balance-history',
    component: () => import('../pages/BalanceHistory.vue'),
  },
  {
    path: '/point-rules/:id',
    name: 'point-rules',
    component: () => import('../pages/PointRules.vue'),
  },
  {
    path: '/coupon-redeem/:cid',  // CouponId or LEADString
    name: 'coupon-redeem',
    component: () => import('../pages/CouponRedeem.vue'),
  },
  {
    path: '/coupon-claim/:cid',  // CouponId
    name: 'coupon-claim',
    component: () => import('../pages/CouponClaim.vue'),
  },
  {
    path: '/item-coupon/:number',
    name: 'item-coupon',
    component: () => import('../pages/ItemCoupon.vue'),
  },
  {
    path: '/personal-info',
    name: 'personal-info',
    component: () => import('../pages/PersonalInfo.vue'),
  },
  {
    path: '/close-account',
    name: 'close-account',
    component: () => import('../pages/CloseAccount.vue'),
  },
  {
    path: '/point-mall/:mid/:mname',
    name: 'point-mall',
    component: () => import('../pages/PointMall.vue'),
  },
  {
    path: '/personal-info/:mid',
    name: 'personal-info-mid',
    component: () => import('../pages/PersonalInfo.vue'),
  },
  {
    path: '/scan',
    name: 'scan',
    component: () => import('../pages/Scan.vue'),
  },
  {
    path: '/partnerverify/:id',
    name: 'partner-verify',
    component: () => import('../pages/Referal_partner_verify.vue'),
  },
  // {
  //   path: '/profile',
  //   name: 'profile',
  //   component: () => import('../pages/Profile.vue'),
  // },
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   component: () => import('../pages/Contact.vue'),
  // },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../pages/Logout.vue'),
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('../pages/NotAuthorized.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('../pages/Error404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
